import { Card } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { calculatorStyles } from 'styles';

const Calculators: React.FC = () => {
  const photoQueries = graphql`
    query {
      jednostresni: file(
        relativePath: { eq: "calculator/jednostresni-card.jpeg" }
      ) {
        id
        childImageSharp {
          fluid(quality: 100, maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dvostresni: file(
        relativePath: { eq: "calculator/dvostresni-card.jpeg" }
      ) {
        id
        childImageSharp {
          fluid(quality: 100, maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cetverostresni: file(
        relativePath: { eq: "calculator/cetverostresni-card.jpeg" }
      ) {
        id
        childImageSharp {
          fluid(quality: 100, maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lType: file(relativePath: { eq: "calculator/l-oblik-card.jpeg" }) {
        id
        childImageSharp {
          fluid(quality: 100, maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      materialPicture: file(
        relativePath: { eq: "calculator/izracun-materijala.jpeg" }
      ) {
        id
        childImageSharp {
          fluid(quality: 100, maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pricePicture: file(
        relativePath: { eq: "calculator/izracun-cijene.jpeg" }
      ) {
        id
        childImageSharp {
          fluid(quality: 100, maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `;

  const { formatMessage } = useIntl();
  const jednostresni = useStaticQuery(photoQueries).jednostresni;
  const dvostresni = useStaticQuery(photoQueries).dvostresni;
  const cetverostresni = useStaticQuery(photoQueries).cetverostresni;
  const lType = useStaticQuery(photoQueries).lType;
  const materialPicture = useStaticQuery(photoQueries).materialPicture;
  const pricePicture = useStaticQuery(photoQueries).pricePicture;

  return (
    <div css={calculatorStyles.homepage}>
      <div css={calculatorStyles.titleWrapper}>
        <h2 css={calculatorStyles.title}>
          <FormattedMessage id="calculator.roof-calculator.title" />
        </h2>
        <p css={calculatorStyles.text}>
          <FormattedMessage id="calculator.roof-calculator.description" />
        </p>
      </div>
      <Card
        title={formatMessage({ id: 'calculator.single-roofed' })}
        navigateTo="jednostresni-krov"
        imageAlt={formatMessage({ id: 'calculator.single-roofed' })}
        imageSrc={jednostresni.childImageSharp}
      />
      <Card
        title={formatMessage({ id: 'calculator.double-sided' })}
        navigateTo="dvostresni-krov"
        imageAlt={formatMessage({ id: 'calculator.double-sided' })}
        imageSrc={dvostresni.childImageSharp}
      />
      <Card
        title={formatMessage({ id: 'calculator.quadruple-sided' })}
        navigateTo="cetverostresni-krov"
        imageAlt={formatMessage({ id: 'calculator.quadruple-sided' })}
        imageSrc={cetverostresni.childImageSharp}
      />
      <Card
        title={formatMessage({ id: 'calculator.l-type' })}
        navigateTo="l-oblik-krov"
        imageAlt={formatMessage({ id: 'calculator.l-type' })}
        imageSrc={lType.childImageSharp}
      />

      <div css={calculatorStyles.titleWrapper}>
        <h2 css={calculatorStyles.title}>
          <FormattedMessage id="calculator.wall-calculator.title" />
        </h2>
        <p css={calculatorStyles.text}>
          <FormattedMessage id="calculator.wall-calculator.description" />
        </p>
      </div>
      <Card
        title={formatMessage({ id: 'calculator.material' })}
        navigateTo="izracun-materijala"
        imageAlt={formatMessage({ id: 'calculator.material' })}
        imageSrc={materialPicture.childImageSharp}
      />
      <Card
        title={formatMessage({ id: 'calculator.price' })}
        navigateTo="izracun-cijene"
        imageAlt={formatMessage({ id: 'calculator.price' })}
        imageSrc={pricePicture.childImageSharp}
      />
    </div>
  );
};

export default Calculators;
